<template>
	<v-container
			fluid
	>
		<v-card class="mt-4">
			<v-card-text>
				<h1>Settings</h1>
			</v-card-text>
			<v-card-text>
				<v-row>
					<v-col>
						<div>Answered exclusion hours: <span class="font-weight-bold">{{ settings.answered_exclusion_hours }}</span></div>
						<div>Answered size: <span class="font-weight-bold">{{ settings.answered_size }}</span></div>
						<div>Indifferent agree threshold: <span class="font-weight-bold">{{ settings.indifferent_agree_threshold }}</span></div>
						<div>Indifferent view threshold: <span class="font-weight-bold">{{ settings.indifferent_view_threshold }}</span></div>
						<div>Newest Cutoff Hours: <span class="font-weight-bold">{{ settings.newest_cutoff_hours }}</span></div>
						<div>Newest Exclusion Hours: <span class="font-weight-bold">{{ settings.newest_exclusion_hours }}</span></div>
						<div>Newest size: <span class="font-weight-bold">{{ settings.newest_size }}</span></div>
						<div>Trend agree threshold: <span class="font-weight-bold">{{ settings.trend_agree_threshold }}</span></div>
						<div>User Indifferent Hours: <span class="font-weight-bold">{{ settings.user_indifferent_hours }}</span></div>
						<div>User Indifferent View Threshold: <span class="font-weight-bold">{{ settings.user_indifferent_view_threshold }}</span></div>
					</v-col>
					<v-col align="right">
						<v-dialog
							v-model="dialogSettings"
							width="700"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-btn v-bind="attrs" v-on="on">
									Edit
								</v-btn>
							</template>

							<ValidationObserver
								ref="settings"
								@submit.prevent="create"
							>
								<form autocomplete="off">
									<v-card>
										<v-card-title>
											Settings
										</v-card-title>

										<v-card-text>
											<v-row>
												<v-col cols="6">
													<VTextFieldValidation
														rules="required|numeric"
														v-model="settings.answered_exclusion_hours"
														label="Answered Exclusion Hours"
													/>
												</v-col>

												<v-col cols="6">
													<VTextFieldValidation
														rules="required|numeric"
														v-model="settings.answered_size"
														label="Answered size"
													/>
												</v-col>
											</v-row>

											<v-row>
												<v-col cols="6">
													<VTextFieldValidation
														rules="required|numeric"
														v-model="settings.indifferent_agree_threshold"
														label="Indifferent Agree Threshold"
													/>
												</v-col>
												<v-col cols="6">
													<VTextFieldValidation
														rules="required|numeric"
														v-model="settings.indifferent_view_threshold"
														label="Indifferent View Threshold"
													/>
												</v-col>
											</v-row>

											<v-row>
												<v-col cols="4">
													<VTextFieldValidation
														rules="required|numeric"
														v-model="settings.newest_cutoff_hours"
														label="Newest Cutoff Hours"
													/>
												</v-col>
												<v-col cols="4">
													<VTextFieldValidation
														rules="required|numeric"
														v-model="settings.newest_exclusion_hours"
														label="Newest Exclusion Hours"
													/>
												</v-col>
												<v-col cols="4">
													<VTextFieldValidation
														rules="required|numeric"
														v-model="settings.newest_size"
														label="Newest Size"
													/>
												</v-col>
											</v-row>

											<v-row>
												<v-col cols="3">
													<VTextFieldValidation
														rules="required"
														v-model="settings.trend_1_exclusion_hours"
														label="Exclusion Hours"
													/>
												</v-col>
												<v-col cols="3">
													<VTextFieldValidation
														rules="required"
														v-model="settings.trend_1_hours"
														label="Trend 1 Hours"
													/>
												</v-col>
												<v-col cols="3">
													<VTextFieldValidation
														rules="required"
														v-model="settings.trend_1_size"
														label="Trend 1 Size"
													/>
												</v-col>
												<v-col cols="3">
													<VTextFieldValidation
														rules="required"
														v-model="settings.trend_1_types"
														label="Trend 1 Types"
													/>
												</v-col>
											</v-row>

											<v-row>
												<v-col cols="3">
													<VTextFieldValidation
														rules="required"
														v-model="settings.trend_2_exclusion_hours"
														label="Exclusion Hours"
													/>
												</v-col>
												<v-col cols="3">
													<VTextFieldValidation
														rules="required"
														v-model="settings.trend_2_hours"
														label="Trend 2 Hours"
													/>
												</v-col>
												<v-col cols="3">
													<VTextFieldValidation
														rules="required"
														v-model="settings.trend_2_size"
														label="Trend 2 Size"
													/>
												</v-col>
												<v-col cols="3">
													<VTextFieldValidation
														rules="required"
														v-model="settings.trend_2_types"
														label="Trend 2 Types"
													/>
												</v-col>
											</v-row>

											<v-row>
												<v-col cols="3">
													<VTextFieldValidation
														rules="required"
														v-model="settings.trend_3_exclusion_hours"
														label="Exclusion Hours"
													/>
												</v-col>
												<v-col cols="3">
													<VTextFieldValidation
														rules="required"
														v-model="settings.trend_3_hours"
														label="Trend 3 Hours"
													/>
												</v-col>
												<v-col cols="3">
													<VTextFieldValidation
														rules="required"
														v-model="settings.trend_3_size"
														label="Trend 3 Size"
													/>
												</v-col>
												<v-col cols="3">
													<VTextFieldValidation
														rules="required"
														v-model="settings.trend_3_types"
														label="Trend 3 Types"
													/>
												</v-col>
											</v-row>

											<v-row>
												<v-col cols="6">
													<VTextFieldValidation
														rules="required|numeric"
														v-model="settings.trend_agree_threshold"
														label="Trend Agree Threshold"
													/>
												</v-col>
											</v-row>

											<v-row>
												<v-col cols="6">
													<VTextFieldValidation
														rules="required"
														v-model="settings.user_indifferent_hours"
														label="User Indifferent Hours"
													/>
												</v-col>
												<v-col cols="6">
													<VTextFieldValidation
														rules="required"
														v-model="settings.user_indifferent_view_threshold"
														label="User Indifferent View Threshold"
													/>
												</v-col>
											</v-row>

										</v-card-text>

										<v-card-actions>
											<v-spacer></v-spacer>
											<v-btn
												@click="saveSettings"
												color="primary"
											>
												Save
											</v-btn>
										</v-card-actions>
									</v-card>
								</form>
							</ValidationObserver>



						</v-dialog>

					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<v-switch
			v-if="false"
			v-model="detailedView"
			label="Detailed view"
		></v-switch>

		<template v-if="detailedView">
			<v-card class="mt-4">
				<v-tabs
					v-model="tabs"
					background-color="transparent"
					slider-color="white"
				>
					<v-tab key="1">Pinned</v-tab>
					<v-tab key="2">Trend 1</v-tab>
					<v-tab key="3">Answered</v-tab>
					<v-tab key="4">Trend 2</v-tab>
					<v-tab key="5">Trend 3</v-tab>
				</v-tabs>

				<v-tabs-items v-model="tabs">
					<v-tab-item key="1">
						<v-data-table
							:loading="loadingPinned"
							:headers="headers"
							:items="itemsPinned"
							item-key="id"

							:server-items-length="totalItemsPinned"
							:options.sync="optionsPinned"
							:footer-props="{'items-per-page-options':[25,50]}"
						>
							<template v-slot:[`item.details.title`]="{ item }">
								<span>{{ item.details.title | ellipsis(40) }}</span>
							</template>
							<template v-slot:[`item.details.text`]="{ item }">
								<span>{{ item.details.text | ellipsis(90) }}</span>
							</template>
							<template v-slot:[`item.views`]="{ item }">
								<span v-if="[`trend_1`, `trend_2`, `trend_3`].includes(item._id)">{{ item.trend[`${item._id}_views`] }}</span>
							</template>
							<template v-slot:[`item.rating`]="{ item }">
								<span v-if="[`trend_1`, `trend_2`, `trend_3`].includes(item._id)">{{ (item.trend[`${item._id}_rating`] * 100).toFixed(2) }}%</span>
							</template>
							<template v-slot:[`item.agrees`]="{ item }">
								<span v-if="[`trend_1`, `trend_2`, `trend_3`].includes(item._id)">{{ item.trend[`${item._id}_agrees`] }}</span>
							</template>
							<template v-slot:[`item.post.type`]="{ item }">
								<template v-if="item.post && item.post.type">
									<svg-icon v-if="item.post.type === `review` && item.post.review.type === `idea`" icon="idea" />
									<svg-icon v-else-if="item.post.type === `review` && item.post.review.type === `love`" icon="love" />
									<svg-icon v-else-if="item.post.type === `review` && item.post.review.type === `warning`" icon="warning" />
									<svg-icon v-else-if="item.post.type === `pledge`" icon="pledge" />
									<svg-icon v-else-if="item.post.type === `post`" icon="pencil" />
									<svg-icon v-else-if="item.post.type === `article`" icon="article" />
									<span v-else>{{ item.post.type }}</span>
								</template>
							</template>
						</v-data-table>
					</v-tab-item>
					<v-tab-item key="2">
						<v-data-table
							:headers="headers"
							:loading="loadingTrend1"
							:items="itemsTrend1"
							item-key="id"

							:server-items-length="totalItemsTrend1"
							:options.sync="optionsTrend1"
							:footer-props="{'items-per-page-options':[25,50]}"
						>
							<template v-slot:[`item.details.title`]="{ item }">
								<span>{{ item.details.title | ellipsis(40) }}</span>
							</template>
							<template v-slot:[`item.details.text`]="{ item }">
								<span>{{ item.details.text | ellipsis(90) }}</span>
							</template>
							<template v-slot:[`item.views`]="{ item }">
								<span v-if="[`trend_1`, `trend_2`, `trend_3`].includes(item._id)">{{ item.trend[`${item._id}_views`] }}</span>
							</template>
							<template v-slot:[`item.rating`]="{ item }">
								<span v-if="[`trend_1`, `trend_2`, `trend_3`].includes(item._id)">{{ (item.trend[`${item._id}_rating`] * 100).toFixed(2) }}%</span>
							</template>
							<template v-slot:[`item.agrees`]="{ item }">
								<span v-if="[`trend_1`, `trend_2`, `trend_3`].includes(item._id)">{{ item.trend[`${item._id}_agrees`] }}</span>
							</template>
							<template v-slot:[`item.post.type`]="{ item }">
								<template v-if="item.post && item.post.type">
									<svg-icon v-if="item.post.type === `review` && item.post.review.type === `idea`" icon="idea" />
									<svg-icon v-else-if="item.post.type === `review` && item.post.review.type === `love`" icon="love" />
									<svg-icon v-else-if="item.post.type === `review` && item.post.review.type === `warning`" icon="warning" />
									<svg-icon v-else-if="item.post.type === `pledge`" icon="pledge" />
									<svg-icon v-else-if="item.post.type === `post`" icon="pencil" />
									<svg-icon v-else-if="item.post.type === `article`" icon="article" />
									<span v-else>{{ item.post.type }}</span>
								</template>
							</template>
						</v-data-table>
					</v-tab-item>
					<v-tab-item key="3">
						<v-data-table
							:headers="headers"
							:loading="loadingAnswered"
							:items="itemsAnswered"
							item-key="id"

							:server-items-length="totalItemsAnswered"
							:options.sync="optionsAnswered"
							:footer-props="{'items-per-page-options':[25,50]}"
						>
							<template v-slot:[`item.details.title`]="{ item }">
								<span>{{ item.details.title | ellipsis(40) }}</span>
							</template>
							<template v-slot:[`item.details.text`]="{ item }">
								<span>{{ item.details.text | ellipsis(90) }}</span>
							</template>
							<template v-slot:[`item.views`]="{ item }">
								<span v-if="[`trend_1`, `trend_2`, `trend_3`].includes(item._id)">{{ item.trend[`${item._id}_views`] }}</span>
							</template>
							<template v-slot:[`item.rating`]="{ item }">
								<span v-if="[`trend_1`, `trend_2`, `trend_3`].includes(item._id)">{{ (item.trend[`${item._id}_rating`] * 100).toFixed(2) }}%</span>
							</template>
							<template v-slot:[`item.agrees`]="{ item }">
								<span v-if="[`trend_1`, `trend_2`, `trend_3`].includes(item._id)">{{ item.trend[`${item._id}_agrees`] }}</span>
							</template>
							<template v-slot:[`item.post.type`]="{ item }">
								<template v-if="item.post && item.post.type">
									<svg-icon v-if="item.post.type === `review` && item.post.review.type === `idea`" icon="idea" />
									<svg-icon v-else-if="item.post.type === `review` && item.post.review.type === `love`" icon="love" />
									<svg-icon v-else-if="item.post.type === `review` && item.post.review.type === `warning`" icon="warning" />
									<svg-icon v-else-if="item.post.type === `pledge`" icon="pledge" />
									<svg-icon v-else-if="item.post.type === `post`" icon="pencil" />
									<svg-icon v-else-if="item.post.type === `article`" icon="article" />
									<span v-else>{{ item.post.type }}</span>
								</template>
							</template>
						</v-data-table>
					</v-tab-item>
					<v-tab-item key="4">
						<v-data-table
							:headers="headers"
							:loading="loadingTrend2"
							:items="itemsTrend2"
							item-key="id"

							:server-items-length="totalItemsTrend2"
							:options.sync="optionsTrend2"
							:footer-props="{'items-per-page-options':[25,50]}"
						>
							<template v-slot:[`item.details.title`]="{ item }">
								<span>{{ item.details.title | ellipsis(40) }}</span>
							</template>
							<template v-slot:[`item.details.text`]="{ item }">
								<span>{{ item.details.text | ellipsis(90) }}</span>
							</template>
							<template v-slot:[`item.views`]="{ item }">
								<span v-if="[`trend_1`, `trend_2`, `trend_3`].includes(item._id)">{{ item.trend[`${item._id}_views`] }}</span>
							</template>
							<template v-slot:[`item.rating`]="{ item }">
								<span v-if="[`trend_1`, `trend_2`, `trend_3`].includes(item._id)">{{ (item.trend[`${item._id}_rating`] * 100).toFixed(2) }}%</span>
							</template>
							<template v-slot:[`item.agrees`]="{ item }">
								<span v-if="[`trend_1`, `trend_2`, `trend_3`].includes(item._id)">{{ item.trend[`${item._id}_agrees`] }}</span>
							</template>
							<template v-slot:[`item.post.type`]="{ item }">
								<template v-if="item.post && item.post.type">
									<svg-icon v-if="item.post.type === `review` && item.post.review.type === `idea`" icon="idea" />
									<svg-icon v-else-if="item.post.type === `review` && item.post.review.type === `love`" icon="love" />
									<svg-icon v-else-if="item.post.type === `review` && item.post.review.type === `warning`" icon="warning" />
									<svg-icon v-else-if="item.post.type === `pledge`" icon="pledge" />
									<svg-icon v-else-if="item.post.type === `post`" icon="pencil" />
									<svg-icon v-else-if="item.post.type === `article`" icon="article" />
									<span v-else>{{ item.post.type }}</span>
								</template>
							</template>
						</v-data-table>
					</v-tab-item>
					<v-tab-item key="5">
						<v-data-table
							:headers="headers"
							:loading="loadingTrend3"
							:items="itemsTrend3"
							item-key="id"

							:server-items-length="totalItemsTrend3"
							:options.sync="optionsTrend3"
							:footer-props="{'items-per-page-options':[25,50]}"
						>
							<template v-slot:[`item.details.title`]="{ item }">
								<span>{{ item.details.title | ellipsis(40) }}</span>
							</template>
							<template v-slot:[`item.details.text`]="{ item }">
								<span>{{ item.details.text | ellipsis(90) }}</span>
							</template>
							<template v-slot:[`item.views`]="{ item }">
								<span v-if="[`trend_1`, `trend_2`, `trend_3`].includes(item._id)">{{ item.trend[`${item._id}_views`] }}</span>
							</template>
							<template v-slot:[`item.rating`]="{ item }">
								<span v-if="[`trend_1`, `trend_2`, `trend_3`].includes(item._id)">{{ (item.trend[`${item._id}_rating`] * 100).toFixed(2) }}%</span>
							</template>
							<template v-slot:[`item.agrees`]="{ item }">
								<span v-if="[`trend_1`, `trend_2`, `trend_3`].includes(item._id)">{{ item.trend[`${item._id}_agrees`] }}</span>
							</template>
							<template v-slot:[`item.post.type`]="{ item }">
								<template v-if="item.post && item.post.type">
									<svg-icon v-if="item.post.type === `review` && item.post.review.type === `idea`" icon="idea" />
									<svg-icon v-else-if="item.post.type === `review` && item.post.review.type === `love`" icon="love" />
									<svg-icon v-else-if="item.post.type === `review` && item.post.review.type === `warning`" icon="warning" />
									<svg-icon v-else-if="item.post.type === `pledge`" icon="pledge" />
									<svg-icon v-else-if="item.post.type === `post`" icon="pencil" />
									<svg-icon v-else-if="item.post.type === `article`" icon="article" />
									<span v-else>{{ item.post.type }}</span>
								</template>
							</template>
						</v-data-table>
					</v-tab-item>
				</v-tabs-items>
			</v-card>
		</template>
		<template v-else>
			<v-card v-for="list in lists" :key="list._id" class="mt-4">
				<v-card-text>
					<h1>{{ list._id.replace(/_/g," ") | capitalize() }}</h1>
				</v-card-text>
				<v-card-text>
					<div>Agree exclusion: <span class="font-weight-bold">{{ list.agree_exclusion }}</span></div>

					<div>Objects: <span class="font-weight-bold">{{ list.posts.length }}</span></div>

					<div class="scroll-container" style="overflow-x: auto;">
						<v-card max-width="200" min-width="250" v-for="(post, i) in list.posts" :key="i" class="ma-2 d-flex flex-column" >
							<PostPreviews v-if="post.post" :post="post.post" />
							<v-spacer></v-spacer>
							<v-container>
								<v-row>
									<v-col>
										<div>Total</div>
										<div><svg-icon icon="agree" /> {{ post.details.agree_count }}</div>
										<div><svg-icon icon="eye" /> {{ post.details.view_count }}</div>
									</v-col>
									<v-col>
									</v-col>
								</v-row>
							</v-container>
						</v-card>
					</div>
				</v-card-text>
			</v-card>
		</template>
	</v-container>
</template>

<style lang="scss">
.scroll-container {
	display: flex;
	overflow-x: auto;

	&::-webkit-scrollbar {
		display: none;
	}
}
</style>
<script>
	import PostPreviews from '@/components/material/PostPreviews'
	import VTextFieldValidation from '@/components/validation/VTextField.vue'

	export default {
		components: {
			PostPreviews,
			VTextFieldValidation,
		},
		computed: {
			items() {
				let items = []
				this.lists.forEach(list => {
					list.posts.forEach(post => {
						post.list = {
							_id: list._id,
							agree_exclusion: list.agree_exclusion,
						}
						items.push(post)
					})
				})
				return items
			},
		},
		data: () => ({
			tabs: null,
			lists: [`pinned`, `trend_1`, `answered`, `trend_2`, `trend_3`],
			loading: false,
			dialogSettings: false,
			totalItemsAnswered: 0,
			totalItemsPinned: 0,
			totalItemsTrend1: 0,
			totalItemsTrend2: 0,
			totalItemsTrend3: 0,
			itemsAnswered: [],
			loadingPinned: false,
			loadingAnswered: false,
			loadingTrend1: false,
			loadingTrend2: false,
			loadingTrend3: false,
			itemsPinned: [],
			itemsTrend1: [],
			itemsTrend2: [],
			itemsTrend3: [],
			settings: {
				answered_exclusion_hours: null,
				answered_size: null,
				indifferent_agree_threshold: null,
				indifferent_view_threshold: null,
				newest_cutoff_hours: null,
				newest_exclusion_hours: null,
				newest_size: null,
				trend_1_exclusion_hours: null,
				trend_1_hours: null,
				trend_1_size: null,
				trend_1_types: null,
				trend_2_exclusion_hours: null,
				trend_2_hours: null,
				trend_2_size: null,
				trend_2_types: null,
				trend_3_exclusion_hours: null,
				trend_3_hours: null,
				trend_3_size: null,
				trend_3_types: null,
				trend_agree_threshold: null,
				user_indifferent_hours: null,
				user_indifferent_view_threshold: null,
			},
			detailedView: true,
			optionsTrend3: {
				sortDesc: [true],
				sortBy: [`date_created`],
			},
			optionsTrend2: {
				sortDesc: [true],
				sortBy: [`date_created`],
			},
			optionsTrend1: {
				sortDesc: [true],
				sortBy: [`date_created`],
			},
			optionsPinned: {
				sortDesc: [true],
				sortBy: [`date_created`],
			},
			optionsAnswered: {
				sortDesc: [true],
				sortBy: [`date_created`],
			},
			headers: [
				{ text: `List ID`, value: `_id`, sortable: false },
				{ text: `Title`, value: `details.title`, sortable: false },
				{ text: `Text`, value: `details.text`, sortable: false },
				{ text: `D Views`, value: `details.views`, sortable: false },
				{ text: `Views`, value: `views`, sortable: false },
				{ text: `Feed CR`, value: `rating`, sortable: false },
				{ text: `Agrees`, value: `agrees`, sortable: false },
				{ text: ``, value: `details.type`, sortable: false },
				{ text: `Recipient`, value: `details.recipient.at_name`, sortable: false },
				{ text: `Author`, value: `details.author.at_name`, sortable: false },
			],
		}),
		methods: {
			saveSettings() {
				this.$refs.settings.validate().then(valid => {
					if (valid) {
						this.$httpInt.put(`v2/app/feed/v2/settings`, this.settings)
							.then(() => {
								this.dialogSettings = false
								this.$httpInt.get(`/v2/app/feed/v2/settings`)
									.then(res => this.settings = res.data.result)
							})
					}
				})
			},
			findPost(postId) {
				for (let i = 0; i < this.lists.length; i++) {
					const post = this.lists[i].posts.find(x => x.i === postId && x)
					if (post) return post
				}
			},
			getPosts(list) {
				this.$httpInt.get(`/v2/app/get-posts`, { ids: list.posts.map(o => o.i) })
					.then(res => {
						list.posts.forEach(o => {
							this.$set(o, 'post', res.data.result.find(x => x._id === o.i))
						})
					})
			},
			refresh (type) {
				let options = this.optionsTrend3
				options.type = type
				if (type === `pinned`) this.loadingPinned = true
				if (type === `answered`) this.loadingAnswered = true
				if (type === `trend_1`) this.loadingTrend1 = true
				if (type === `trend_2`) this.loadingTrend2 = true
				if (type === `trend_3`) this.loadingTrend3 = true
				this.$httpInt.get(`/v2/app/feed/v2/lists`, { params: options })
					.then(res => {
						if (type === `trend_1`) {
							this.itemsTrend1 = res.data.result
							this.totalItemsTrend1 = res.data.pagination.total
							this.loadingTrend1 = false
						}
						if (type === `trend_2`) {
							this.itemsTrend2 = res.data.result
							this.totalItemsTrend2 = res.data.pagination.total
							this.loadingTrend2 = false
						}
						if (type === `trend_3`) {
							this.itemsTrend3 = res.data.result
							this.totalItemsTrend3 = res.data.pagination.total
							this.loadingTrend3 = false
						}
						if (type === `pinned`) {
							this.itemsPinned = res.data.result
							this.totalItemsPinned = res.data.pagination.total
							this.loadingPinned = false
						}
						if (type === `answered`) {
							this.itemsAnswered = res.data.result
							this.totalItemsAnswered = res.data.pagination.total
							this.loadingAnswered = false
						}
					})

				this.$httpInt.get(`/v2/app/feed/v2/settings`)
					.then(res => this.settings = res.data.result)
			},
		},
		mounted () {
			//this.refresh()
		},
		name: `FeedV2`,
		watch: {
			options: {
				handler() {
					//this.refresh()
				},
			},
			optionsAnswered: {
				handler() { this.refresh(`answered`) },
			},
			optionsPinned: {
				handler() { this.refresh(`pinned`) },
			},
			optionsTrend1: {
				handler() { this.refresh(`trend_1`) },
			},
			optionsTrend2: {
				handler() { this.refresh(`trend_2`) },
			},
			optionsTrend3: {
				handler() { this.refresh(`trend_3`) },
			},
		},
	}
</script>
