<template>
	<div>
		<v-list-item v-if="post.author" class="pt-2">
			<Avatar
				v-if="post.author && post.author.profile_image"
				:source="post.author.profile_image"
				class="mr-2"
				size="30"
			/>

				<v-list-item-content class="pa-0">
					<v-list-item-title class="subtitle-2">
						<div v-if="post.author">
							<a
									class="font-weight-bold"
									v-on:click="$router.push({name: 'Profile', params: {id: post.author.at_name}})"
									v-text="post.author.display_name"
							/>
						</div>
						<span v-if="post.age && typeof post.age === `object`" class="caption">
							{{ post.age.hour && `${post.age.hour}h`}}
							{{ post.age.day && `${post.age.day}d`}}
							{{ post.age.week && `${post.age.week}w`}}
						</span>

						<span v-if="post.age && typeof post.age !== `object`" class="caption">
							{{ post.age }}
						</span>
				</v-list-item-title>
			</v-list-item-content>
		</v-list-item>

		<div>

			<v-list-item v-if="post.recipient">
				<Avatar
					:source="post.recipient.profile_image"
					:sub-source="post.review.type"
					class="mr-2"
					size="30"
				/>

				<v-list-item-content>
					<v-list-item-title class="subtitle-2">
						<div v-if="post.recipient">
							<a
								class="font-weight-bold"
								v-on:click="$router.push({name: 'Profile', params: {id: post.recipient.at_name}})"
							>{{ post.recipient.display_name }}</a>
						</div>
					</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-list-item>
				<v-list-item-content class="pt-1" style="cursor: pointer" @click="$router.push({name: 'Post', params: {id: post.id}})">
					<v-list-item-title class="subtitle-1 font-weight-bold">
						{{ post.title }}
					</v-list-item-title>
					<span class="body-2">
						{{ post.text | ellipsis(150) }}
					</span>
					<v-list-item-subtitle>
						<v-img
							v-if="post.image_urls"
							:src="post.image_urls[0]"
							aspect-ratio="1"
							class="grey lighten-2 mt-2"
							max-height="300"
						/>
						<v-img
							v-else-if="post.image_url"
							:src="post.image_url"
							aspect-ratio="1"
							class="grey lighten-2 mt-2"
							max-height="300"
						/>
					</v-list-item-subtitle>

				</v-list-item-content>
			</v-list-item>
		</div>
	</div>
</template>

<script>
	export default {
		name: `ComponentPostPreview`,
		props: [`post`],
	}
</script>